import React from "react";
import PropTypes from "prop-types";

const RadioButton = ({
  id,
  value,
  currentValue,
  onSelect,
  label,
  labelClassName,
}) => {
  const isChecked = currentValue === value;
  return (
    <span className="flex items-center justify-center">
      <input
        id={id}
        type="radio"
        value={value}
        checked={isChecked}
        onChange={onSelect}
      />
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
    </span>
  );
};

export default RadioButton;

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  labelClassName: PropTypes.string,
};
